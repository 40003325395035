import React, { useContext } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'

export const fragment = graphql`
  fragment WorkRelatedFragment on WordPress_Project_Workfields {
    relatedProjects {
      projects {
        ... on WordPress_Project {
          slug
          categories {
            edges {
              node {
                name
              }
            }
          }
          workFields {
            featuredInfos {
              title
              image {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const WorkRelated = ({ related }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)

  return (
    <Outer>
      <Title>Related Projects</Title>

      <WrapRelated>
        {related.projects.map((project, i) => (
          <RelatedItem
            key={i}
            to={`/work/${project.slug}`}
            onClick={event => {
              event.preventDefault()
              setTransitionActive(true)
              setTimeout(() => {
                navigate(`/work/${project.slug}`)
                setTransitionActive(false)
              }, 500)
            }}
          >
            <RelatedImg
              fluid={project.workFields.featuredInfos.image.imageFile.childImageSharp.fluid}
            />
            {project.categories.edges.map((cat, i) => (
              <Cat key={i}>{cat.node.name} </Cat>
            ))}
            <h2> {project.workFields.featuredInfos.title} </h2>
          </RelatedItem>
        ))}
      </WrapRelated>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  padding: 0 15% 20rem 15%;

  @media (max-width: 1260px) {
    padding: 100px 7.5%;
  }

  @media (max-width: 1024px) {
    padding: 75px 7.5%;
  }

  @media (max-width: 650px) {
    padding: 85px 30px 65px 30px;
  }
`

const Title = styled.h2`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5rem;
  letter-spacing: -0.075rem;
  margin-left: 2.5rem;
  margin-bottom: 3.5rem;

  @media (max-width: 1024px) {
    font-size: 5.5rem;
  }

  @media (max-width: 650px) {
    margin-left: 0;
  }
`

const WrapRelated = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 2.5rem;
  margin-right: 2.5rem;

  @media (max-width: 650px) {
    margin-left: 0;
    margin-right: 0;
  }

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }
`

const RelatedItem = styled(Link)`
  width: calc(50% - 35px);
  padding: 0 17.5px;

  @media (max-width: 1024px) {
    width: calc(50% - 25px);
    padding: 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;

    &:first-child {
      margin-bottom: 5rem;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 4.5rem;
    letter-spacing: -0.075rem;
    transition: color 0.25s ease-in-out;

    @media (max-width: 650px) {
      font-size: 5rem;
    }
  }
`

const RelatedImg = styled(Img)`
  ${aspectRatio(537, 469)}
  margin-bottom: 2rem;

  &:after {
    content: '';
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colours.pink};
    mix-blend-mode: screen;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  &:hover:after {
    opacity: 0.7;
  }
`

const Cat = styled.span`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.22rem;
  text-transform: uppercase;
  margin-right: 1.5rem;
  line-height: 1.75;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }

  @media (max-width: 650px) {
    margin-left: 0;
    font-size: 1.75rem;
  }
`

export default WorkRelated

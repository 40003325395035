import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

export const fragment = graphql`
  fragment WorkIntroFragment on WordPress_Project_Workfields {
    introInfos {
      siteLinkText
      siteLink
      clientName
      location
      text
      title
      involvement {
        item
      }
      featuredOn {
        link
        title
      }
    }
  }
`

const WorkIntro = ({ intro }) => {
  const infosAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const infosTwoAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1050,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const infosThreeAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1100,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const infosFourAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,55px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1150,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const infosFiveAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1200,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <WrapBrief>
        <SmallTitle> 01. </SmallTitle>
        <Title> {intro.title} </Title>
        <MainContent dangerouslySetInnerHTML={{ __html: intro.text }} />
      </WrapBrief>

      <WrapInfos>
        <MobileFlex>
          <InfoItem style={infosAnimation}>
            <h4>Industry</h4>
            <p> {intro.clientName} </p>
          </InfoItem>

          <InfoItem style={infosTwoAnimation}>
            <h4>Location</h4>
            <p> {intro.location} </p>
          </InfoItem>
        </MobileFlex>

        <MobileFlex>
          <InfoItem style={infosThreeAnimation}>
            <h4>Involvement</h4>
            {intro.involvement.map((involve, i) => (
              <p key={i}> {involve.item} </p>
            ))}
          </InfoItem>

          {intro.featuredOn && (
            <InfoItem style={infosFourAnimation} className="featured-on">
              <h4>Featured by</h4>
              {intro.featuredOn.map((feature, i) => (
                <p className="award-link" key={feature.title}>
                  <a href={feature.link} target="_blank" rel="noopener noreferrer">
                    {feature.title}
                  </a>
                </p>
              ))}
            </InfoItem>
          )}

          {intro.siteLink && (
            <InfoItem style={infosFiveAnimation}>
              <h4>Visit</h4>
              <a
                href={intro.siteLink}
                target="_blank"
                rel="noopener noreferrer"
                className="visit-link"
              >
                {intro.siteLinkText}
              </a>
            </InfoItem>
          )}
        </MobileFlex>
      </WrapInfos>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 14.5rem 15% 0 15%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    padding: 5rem 10% 0 10%;
  }

  @media (max-width: 650px) {
    padding: 0 3.5rem;
  }
`

const WrapBrief = styled.div`
  width: 66%;
  margin-right: 14%;
  order: 1;

  @media (max-width: 769px) {
    margin-right: 5%;
    width: 100%;
    order: 2;
    margin: 20rem 0 10rem 0;
  }

  @media (max-width: 650px) {
    margin: 15rem 5% 0 0;
  }
`

const WrapInfos = styled.div`
  margin-top: 7.5rem;
  width: 20%;
  order: 2;

  @media (max-width: 769px) {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
    margin-top: 0;
  }
`

const MobileFlex = styled.div`
  display: block;
  width: 100%;

  @media (max-width: 769px) {
    width: 50%;
    text-align: center;
  }

  @media (max-width: 650px) {
    text-align: left;

    &:first-child {
      width: 40%;
    }

    &:last-child {
      width: 60%;
    }
  }
`

const InfoItem = styled(animated.div)`
  width: 100%;

  h4 {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 0.22rem;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 650px) {
      margin-top: 3rem;
      font-size: 1.7rem;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: -0.075rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 3.5rem;
    }

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      font-size: 2.25rem;
    }
  }

  & .visit-link {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: -0.075rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${props => props.theme.colours.footer};
    cursor: pointer;
    transition: color 0.25s ease;

    &:hover {
      color: ${props => props.theme.colours.lightGrey};
    }

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      font-size: 2.25rem;
    }
  }

  &.featured-on {
    margin-bottom: 3.5rem;

    .award-link a {
      color: ${props => props.theme.colours.grey};
      font-family: ${props => props.theme.fonts.circular};
      font-weight: 700;
      font-size: 1.6rem;
      letter-spacing: -0.075rem;
      border-bottom: 1px solid ${props => props.theme.colours.footer} !important;
      cursor: pointer;
      transition: all 0.25s ease;

      &:hover {
        color: ${props => props.theme.colours.lightGrey};
        border-bottom: 1px solid ${props => props.theme.colours.footer} !important;
      }

      @media (max-width: 1024px) {
        font-size: 2rem;
      }

      @media (max-width: 650px) {
        font-size: 2.25rem;
      }
    }
  }
`

const SmallTitle = styled.p`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.22rem;
  text-transform: uppercase;
  margin-left: 3rem;

  @media (max-width: 1024px) {
    margin-left: 0;
    font-size: 1.5rem;
  }

  @media (max-width: 650px) {
    margin-top: 3rem;
    margin-left: 0;
    font-size: 1.7rem;
  }
`

const Title = styled.h2`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5rem;
  letter-spacing: -0.075rem;
  margin-left: 2.5rem;
  margin-bottom: 3.5rem;

  @media (max-width: 769px) {
    margin-left: 0;
  }
`

const MainContent = styled.p`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.75;
  margin-left: 2.5rem;
  white-space: pre-line;

  @media (max-width: 769px) {
    margin-left: 0;
    font-size: 2rem;
  }

  @media (max-width: 650px) {
    margin-left: 0;
    font-size: 2.25rem;
    line-height: 1.9;
  }
`

export default WorkIntro

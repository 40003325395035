import React, { useContext, useState, useEffect, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'
import { TransitionContext } from '../context/transition-context'
import { ButtonContext } from '../context/button-context'
import InView from '../in-view'
import { ScrollbarContext } from '../context/scrollbar-context'

import AllWork from '../../images/all_work.svg'

export const fragment = graphql`
  fragment WorkHeroFragment on WordPress_Project_Workfields {
    heroInfos {
      title
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const WorkHero = ({ hero }) => {
  // eslint-disable-next-line no-unused-vars
  const triggerRef = useRef()
  const wrapRef = useRef()
  const [isDesktop, setIsDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const [scrollbar] = useContext(ScrollbarContext)

  useEffect(() => {
    const fixedPosition = ({ offset = 0 }) => {
      if (wrapRef.current) {
        wrapRef.current.style.top = `${offset.y}px`
      }
    }

    // Hooks into the Smooth Scrollbar instance that's passed as context
    // to create an artificially fixed element, sync to the current
    // Smooth Scrollbar offset
    if (scrollbar) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
  }, [scrollbar])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const imageAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 500,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const titleAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 900,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const allAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(-50px,0px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1200,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      {!isDesktop && (
        <InView element={triggerRef.current} setToggle={setButtonTriggers} location="hero">
          <TriggerButton ref={triggerRef} />
        </InView>
      )}
      <Scroll>
        <p>scroll</p>
        <span></span>
      </Scroll>
      <HeroImg style={imageAnimation} fluid={hero.image.imageFile.childImageSharp.fluid} />
      <animated.h1 style={titleAnimation}>{hero.title}</animated.h1>

      <SquareOuter ref={wrapRef}>
        <WrapSquareAll
          style={allAnimation}
          to="/work"
          onClick={event => {
            event.preventDefault()
            setTransitionActive(true)
            setTimeout(() => {
              navigate(`/work`)
              setTransitionActive(false)
            }, 500)
          }}
        >
          <AllWork />
        </WrapSquareAll>
      </SquareOuter>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 14.5rem 17.5% 0 17.5%;

  @media (max-width: 1260px) {
    padding: 17rem 12.5%;
  }

  @media (max-width: 1024px) {
    padding: 22rem 5% 5rem 5%;
  }

  @media (max-width: 650px) {
    padding: 125px 0 0 0;
  }

  @media (max-width: 650px) and (min-height: 750px) {
    padding: 200px 0 0 0;
  }

  h1 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 6.5rem;
    line-height: 1;
    letter-spacing: -0.075rem;
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 5rem;
      margin: 3rem 0;
    }

    @media (max-width: 650px) {
      font-size: 5.5rem;
      margin: 3rem auto;
    }
  }
`

const TriggerButton = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
  height: 1px;
  background-color: transparent;
`

const move = keyframes`
  0% {
    transform: translateX(0);
    width: 40px;
  }

  50% {
    transform: translateX(25px);
    width: 30px;
  }

  100% {
    transform: translateX(0);
    width: 40px;
  }
`

const Scroll = styled.div`
  position: absolute;
  top: 50%;
  left: -6rem;
  width: 200px;
  height: 50px;
  transform: rotate(90deg);

  @media (max-width: 1000px) {
    display: none;
  }

  p {
    font-size: 1.2rem;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.lightGrey};
    font-weight: 700;
    display: inline-block;
    margin-right: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  span {
    margin: 0 auto;
    width: 40px;
    height: 1px;
    background-color: ${props => props.theme.colours.lightGrey};
    display: inline-block;
    line-height: 2;
    animation: ${move} 1.75s ease-in-out infinite;
  }
`

const HeroImg = styled(animated(Img))`
  width: 92%;
  margin: 0 auto;

  @media (min-width: 1700px) {
    width: 100%;
  }
`

const WrapSquareAll = styled(animated(Link))`
  position: absolute;
  left: 5rem;
  bottom: 5rem;
  pointer-events: all;
`

const SquareOuter = styled.nav`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;

  @media (max-width: 650px) {
    display: none;
  }
`

export default WorkHero

import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

const WorkResults = ({ block }) => (
  <Outer>
    <ResultsBg
      fluid={block.background.imageFile.childImageSharp.fluid}
      style={{ position: 'absolute' }}
    />

    <WrapResultsContent>
      <SmallTitle>{block.smallTitle}</SmallTitle>
      <Title>{block.title}</Title>

      <WrapResults>
        {block.resultItem.map((result, i) => (
          <ResultItem key={i}>
            <ResultNumber>{result.number}</ResultNumber>
            <ResultText>{result.relatedText}</ResultText>
          </ResultItem>
        ))}
      </WrapResults>

      {block.text && <TextResults>{block.text}</TextResults>}
    </WrapResultsContent>
  </Outer>
)

const Outer = styled.div`
  position: relative;
`

const ResultsBg = styled(Img)`
  width: 100%;
  height: 100%;
  z-index: 1;
`

const WrapResultsContent = styled.div`
  position: relative;
  max-width: 100%;
  padding: 15rem 15%;
  z-index: 5;

  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 5rem 10% 10rem 10%;
  }

  @media (max-width: 650px) {
    max-width: 100%;
    padding: 5rem 3.5rem 5.5rem 3.5rem;
  }
`

const SmallTitle = styled.p`
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.22rem;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    margin-left: -3rem;
    font-size: 1.5rem;
  }

  @media (max-width: 650px) {
    margin-top: 3rem;
    margin-left: 0;
    font-size: 1.7rem;
  }
`

const Title = styled.h2`
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5rem;
  letter-spacing: -0.075rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-bottom: 7.5rem;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`

const WrapResults = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 2.5rem;
  margin-right: 2.5rem;

  @media (max-width: 769px) {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  @media (max-width: 620px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
`

const ResultItem = styled.div`
  width: 28rem;
  height: 28rem;
  padding: 1.6rem 2.8rem;
  border: 1px solid ${props => props.theme.colours.white};
  background: rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  @media (max-width: 1300px) {
    width: 24rem;
    height: 24rem;
  }

  @media (max-width: 1150px) {
    width: 22rem;
    height: 22rem;
  }

  @media (max-width: 1050px) {
    width: 20rem;
    height: 20rem;
  }

  @media (max-width: 769px) {
    margin-bottom: 3.5rem;
    width: 22.5rem;
    height: 22.5rem;
  }

  @media (max-width: 620px) {
    margin-bottom: 3.5rem;
    width: 30rem;
    height: 30rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ResultNumber = styled.h5`
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 6rem;

  @media (max-width: 1150px) {
    font-size: 4rem;
  }

  @media (max-width: 650px) {
    font-size: 6.5rem;
  }
`

const ResultText = styled.p`
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2;
  margin-bottom: 0;

  @media (max-width: 1150px) {
    font-size: 1.4rem;
  }

  @media (max-width: 650px) {
    font-size: 2.3rem;
  }
`

const TextResults = styled.p`
  margin-top: 8.5rem;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2;
  margin: 8.5rem 2.5rem 0 2.5rem;
  max-width: 75%;

  @media (max-width: 1024px) {
    margin: 3rem 0 0 0;
    font-size: 2rem;
    max-width: 100%;
  }

  @media (max-width: 650px) {
    margin: 8.5rem 0 0 0;
    font-size: 2.25rem;
    max-width: 100%;
  }
`

export default WorkResults

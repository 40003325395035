import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import Img from 'gatsby-image'
import ReactCompareImage from 'react-compare-image'
import Observer from '../observer'

const BeforeAfter = ({ block }) => {
  const data = useStaticQuery(graphql`
    query ImacQuery {
      allFile(filter: { dir: { regex: "/src/" }, name: { eq: "imac" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 2500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const [animatedQuote, setAnimatedQuote] = useState(false)
  const ref = useRef()

  const infoAnimation = useSpring({
    transform: animatedQuote ? `translate3d(0px,0px,0px)` : `translate3d(0,50px,0)`,
    opacity: animatedQuote ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animatedQuote} setToggle={setAnimatedQuote}>
        <Inner style={infoAnimation} ref={ref}>
          <BeforeLabel>
            <p>Before</p>
          </BeforeLabel>
          <AfterLabel>
            <p>After</p>
          </AfterLabel>
          <ImacContainer>
            <CompareContainer>
              <ReactCompareImage
                leftImage={block.beforeImage.imageFile.childImageSharp.sizes.originalImg}
                rightImage={block.afterImage.imageFile.childImageSharp.sizes.originalImg}
                sliderLineColor="#F74EA1"
                sliderLineWidth="5"
                handleSize="80"
                hover
              />
            </CompareContainer>
            <Imac>
              <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
            </Imac>
          </ImacContainer>
        </Inner>
      </Observer>
    </Outer>
  )
}

export default BeforeAfter

const CompareContainer = styled.div`
  position: absolute;
  top: 5%;
  left: 15%;
  width: 70%;
  z-index: 5;
`

const ImacContainer = styled.section`
  position: relative;
  width: 100%;
`

const Imac = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`

const Outer = styled.div`
  position: relative;
  padding: 0 15%;

  @media (max-width: 650px) {
    padding: 65px 30px 65px 30px;
  }
`

const Inner = styled(animated.div)`
  position: relative;
`

const BeforeLabel = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: -6%;
  top: 45%;
  transform: translateY(-50%);
  z-index: 10;

  @media (max-width: 1400px) {
    left: -10%;
  }

  @media (max-width: 1150px) {
    left: -15%;
  }

  @media (max-width: 750px) {
    display: none;
  }

  p {
    font-size: 5rem;
    line-height: 0.8;
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.sofia};
    display: inline-block;

    @media (max-width: 650px) {
      font-size: 4rem;
      line-height: 1.25;
    }
  }
`

const AfterLabel = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: -2%;
  top: 45%;
  transform: translateY(-50%);
  z-index: 10;

  @media (max-width: 1400px) {
    right: -6%;
  }

  @media (max-width: 1150px) {
    right: -10%;
  }

  @media (max-width: 750px) {
    display: none;
  }

  p {
    font-size: 5rem;
    line-height: 0.8;
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.sofia};
    display: inline-block;

    @media (max-width: 650px) {
      font-size: 4rem;
      line-height: 1.25;
    }
  }
`

import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import VideoElement from '../video.js'

import WorkResults from './results'
import WorkQuote from './quote'
import Spacer from '../spacer'
import BeforeAfter from './before-after'

export const fragment = graphql`
  fragment WorkMainContentFragment on WordPress_Project_Workfields {
    mainContent {
      ... on WordPress_Project_Workfields_MainContent_Image {
        fieldGroupName
        dropShadow
        imageSize
        image {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      ... on WordPress_Project_Workfields_MainContent_TextAndTitle {
        fieldGroupName
        sectionNumber
        text
        title
      }
      ... on WordPress_Project_Workfields_MainContent_Video {
        fieldGroupName
        videoType
        videoSize
        embedLink
        loop
        fileLink {
          mediaItemUrl
        }
        posterImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      ... on WordPress_Project_Workfields_MainContent_ResultsInfos {
        fieldGroupName
        background {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        resultItem {
          number
          relatedText
        }
        smallTitle
        text
        title
      }
      ... on WordPress_Project_Workfields_MainContent_ClientQuote {
        fieldGroupName
        name
        quote
        position
      }
      ... on WordPress_Project_Workfields_MainContent_Spacer {
        fieldGroupName
        size
      }
      ... on WordPress_Project_Workfields_MainContent_BeforeAndAfter {
        fieldGroupName
        beforeImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              sizes {
                originalImg
              }
            }
          }
        }
        afterImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              sizes {
                originalImg
              }
            }
          }
        }
      }
    }
  }
`

const SwitchWorkContent = (fieldName, block, i) => {
  /* eslint-disable */
  switch(fieldName) {
    case 'Project_Workfields_MainContent_Image':
      return (
        <Image key={i} className={block.dropShadow === 'yes' ? 'drop-shadow' : ''} fluid={block.image.imageFile.childImageSharp.fluid} imageSize={block.imageSize} />
      )
      break;
    case 'Project_Workfields_MainContent_TextAndTitle':
      return (
        <WrapText key={i}>
          <SmallTitle> {block.sectionNumber} </SmallTitle>
          <Title> {block.title} </Title> 
          <MainContent> {block.text} </MainContent>    
        </WrapText>
      )
      break;
    case 'Project_Workfields_MainContent_Video':
      return (
        <VideoElement key={i} block={block} videoSize={block.videoSize} loop={block.loop} isBlog="false" full />
      )
      break;
    case 'Project_Workfields_MainContent_ResultsInfos':
      return (
        <WorkResults key={i} block={block} />
      )
      break;
    case 'Project_Workfields_MainContent_ClientQuote':
      return (
        <WorkQuote key={i} block={block} />
      )
      break;
      case 'Project_Workfields_MainContent_Spacer':
      return (
        <Spacer key={i} block={block} size={block.size} />
      )
      break;
      case 'Project_Workfields_MainContent_BeforeAndAfter':
      return (
        <BeforeAfter key={i} block={block} />
      )
      break;
    default:
      return null;
  }
  /* eslint-enable */
}

const WorkMain = ({ mainContent }) => (
  <Outer>{mainContent.map((block, i) => SwitchWorkContent(block.fieldGroupName, block, i))}</Outer>
)

const Outer = styled.div`
  position: relative;

  @media (max-width: 1260px) {
    padding: 0 0 65px 0;
  }

  @media (max-width: 650px) {
    padding: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`

const Image = styled(Img)`
  margin: ${props => (props.imageSize === 'full_width' ? '0' : '0 15%')};

  @media (max-width: 1024px) {
    margin: ${props => (props.imageSize === 'full_width' ? '0' : '0 10%')};
  }

  @media (max-width: 650px) {
    margin: ${props => (props.imageSize === 'full_width' ? '0' : '0 7%')};
  }

  &.drop-shadow {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  }
`

const WrapText = styled.div`
  padding: 0 15%;
  width: 80%;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 5rem 10% 0 10%;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
  }
`

const SmallTitle = styled.p`
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.22rem;
  text-transform: uppercase;
  margin-left: 3rem;

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-top: 3rem;
    font-size: 1.5rem;
  }

  @media (max-width: 650px) {
    margin-top: 3rem;
    margin-left: 3rem;
    font-size: 1.7rem;
  }
`

const Title = styled.h2`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5rem;
  letter-spacing: -0.075rem;
  margin-left: 2.5rem;
  margin-bottom: 3.5rem;

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  @media (max-width: 650px) {
    margin-left: 3rem;
  }
`

const MainContent = styled.p`
  color: ${props => props.theme.colours.grey};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.75;
  margin-left: 2.5rem;
  white-space: pre-line;

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  @media (max-width: 650px) {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
    font-size: 2.25rem;
    line-height: 1.9;
  }
`

export default WorkMain

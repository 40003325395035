import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import WorkHero from '../components/single-work/hero'
import WorkIntro from '../components/single-work/intro'
import WorkRelated from '../components/single-work/related'
import WorkMain from '../components/single-work/main-content'
import GetCracking from '../components/get-cracking'
import SEO from '../components/single-work/seo'

const SingleProject = ({ data, pageContext }) => (
  <Layout>
    <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.projectBy.seo} />
    <WorkHero hero={data.wordPress.projectBy.workFields.heroInfos} />
    <WorkIntro intro={data.wordPress.projectBy.workFields.introInfos} />
    <WorkMain mainContent={data.wordPress.projectBy.workFields.mainContent} />
    <GetCracking />
    <WorkRelated related={data.wordPress.projectBy.workFields.relatedProjects} />
  </Layout>
)

export default SingleProject

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      projectBy(projectId: $wordpressId) {
        ...SeoProjectFragment
        workFields {
          ...WorkHeroFragment
          ...WorkIntroFragment
          ...WorkRelatedFragment
          ...WorkMainContentFragment
        }
      }
    }
  }
`

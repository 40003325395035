import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Observer from '../observer'

const WorkQuote = ({ block }) => {
  const [animatedQuote, setAnimatedQuote] = useState(false)
  const ref = useRef()

  const infoAnimation = useSpring({
    transform: animatedQuote ? `translate3d(0px,0px,0px)` : `translate3d(0,50px,0)`,
    opacity: animatedQuote ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animatedQuote} setToggle={setAnimatedQuote} />

      <Quote style={infoAnimation} ref={ref}>
        <p>{block.quote}</p>
      </Quote>

      <QuoteCredits>
        <p>{block.name}</p>
        <p>{block.position}</p>
      </QuoteCredits>

      <hr />
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  padding: 0 15%;

  @media (max-width: 1024px) {
    padding: 13rem 10% 0 10%;
  }

  @media (max-width: 650px) {
    padding: 65px 30px 65px 30px;
  }

  hr {
    margin: 0 2.5rem;

    @media (max-width: 769px) {
      margin: 0;
    }
  }
`

const Quote = styled(animated.div)`
  width: 85%;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 1024px) {
    width: 100%;
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2.5rem;
    margin: 0 2.5rem;

    @media (max-width: 1024px) {
      font-size: 3rem;
    }

    @media (max-width: 769px) {
      margin: 0;
    }
  }
`

const QuoteCredits = styled.div`
  text-align: center;
  margin: 5rem 0 15rem 0;

  @media (max-width: 1024px) {
    margin: 2.5rem 0 7.5rem 0;
  }

  @media (max-width: 650px) {
    margin: 2.5rem 0 7.5rem 0;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2rem;
    margin: 0;

    @media (max-width: 1024px) {
      font-size: 3rem;
    }
  }
`

export default WorkQuote
